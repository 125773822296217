import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class ProductCategory {
  @JsonProperty("product_category_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty("bio_short") bio!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
