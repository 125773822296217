

























































































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BackBar from "@/components/BackBar.vue"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import ProductApi from "@/api/Product/ProductApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import ProductCategory from "@/types/Product/ProductCategory"
import ProductCategoryFilterDrawer from "@/components/Products/ProductCategoryFilterDrawer.vue"
import CompareProductsBar from "@/components/Products/CompareProductsBar.vue"

// eslint-disable-next-line
import { Route } from "vue-router"

@Component({
  name: "ProductCategoryList",
  components: {
    ProductCategoryFilterDrawer,
    BackBar,
    CompareProductsBar
  }
})
export default class ProductCategoryList extends mixins(BreakpointMixin, InputMixin) {
  private categories: ProductCategory[] = []

  private filterDrawer = false

  private groupId = parseInt(this.$route.params.id, 10)

  private query = {}

  private filtering = false

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newValue: Route, oldValue: Route) {
    // Router from vue-router
    if (!oldValue) return
    if (newValue.name !== oldValue.name) this.fetchData()
    const lang = this.$i18n.locale

    if (newValue.query.lang !== oldValue.query.lang) {
      return
    } else {
    this.$router.push({ name: "ProductGroupList", query: { lang } })
  }
  }

  private back() {
    localStorage.removeItem("productFilter")
    const lang = this.$i18n.locale
    this.$router.push({ name: "ProductGroupList", query: { lang } })
  }

  private viewProduct(cat: ProductCategory) {
    const lang = this.$i18n.locale
    this.$router.push({
      name: "ProductItemDetail",
      params: { id: `${this.groupId}`, detailId: `${cat.id}` },
      query: { lang }
    })
  }

  private generateRoute(cat: ProductCategory) {
    return {
      name: "ProductItemDetail",
      params: { id: `${this.groupId}`, detailId: `${cat.id}` },
      query: { lang: this.$i18n.locale }
    }
  }

  private mounted() {
    this.fetchData()
  }

  /** Group Product Categories */
  private get groupCategories() {
    const LIMIT_DESTINATION = 3
    return this.categories
      .map((item, index) => {
        if (index % LIMIT_DESTINATION === 0) {
          return this.categories.slice(index, index + LIMIT_DESTINATION)
        }
      })
      .filter((item) => {
        return item
      })
  }

  private doFilter(filter: {}) {
    this.query = filter
    this.fetchData()
  }

  private get filterButtonSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 80
    return 56
  }

  private get filterButtonIconSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 42
    return 30
  }

  private fetchData() {
    this.filtering = true
    this.groupId = parseInt(this.$route.params.id, 10)
    ProductApi.listCategories(this.groupId, this.query).then(({ data }) => {
      this.categories = _.map(data, (d) => deserialize<ProductCategory>(d, ProductCategory))
      this.filtering = false
    })
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 247
    }
    if (bp.mdAndUp) {
      return 165
    }
    return 155
  }
}
